const Header = () => {
    return (
        <header className="d-flex align-items-center justify-content-evenly font-funny py-3 flex-wrap">
            <div className="d-block px-3 py-2">
                <i className="bi bi-envelope-fill me-2"></i>
                <a href="mailto:malaybhavsar.290@gmail.com" className="text-decoration-none text-light">
                    malaybhavsar.290@gmail.com
                </a>
            </div>
            <div className="d-block px-3 py-2">
                <i className="bi bi-github me-2"></i>
                <a href="https://github.com/Leo-Malay" className="text-decoration-none text-light">
                    @leo-malay
                </a>
            </div>
            <div className="d-block px-3 py-2">
                <i className="bi bi-linkedin me-2"></i>
                <a href="https://www.linkedin.com/in/leo-malay-bhavsar/" className="text-decoration-none text-light">
                    @leo-malay-bhavsar
                </a>
            </div>
        </header>
    );
};

export default Header;
