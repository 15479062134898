const Footer = () => {
    return (
        <footer className="small text-center">
            <p>
                <b>Malay Bhavsar</b> © {new Date().getFullYear()} All Right Reserved.
            </p>
        </footer>
    );
};

export default Footer;
