import Footer from "./Footer";
import Header from "./Header";

const App = () => {
    return (
        <div className="App container-md">
            <Header />

            {/* Landing */}
            <div className="page-heading font-funny my-5 p-5">
                <h1 className="page-heading">
                    <span className="text-warning">Hii</span>, I'm
                    <br />
                    Leo<span className="text-success">Malay</span>
                </h1>
            </div>
            {/* Landing */}

            {/* Resume */}
            <div className="mx-4">
                <div className="card text-bg-dark px-1">
                    <div className="card-body d-flex align-items-center justify-content-between flex-wrap flex-row-reverse flex-lg-row">
                        <h5 className="card-text my-1" style={{ textAlign: "justify" }}>
                            <i class="bi bi-bell-fill"></i> Feel free to grab a copy of my resume during your visit!
                        </h5>
                        <a href="/resume.pdf" className="btn btn-warning mt-3 mt-lg-0">
                            <i class="bi bi-file-earmark-text-fill"></i> Resume
                        </a>
                    </div>
                </div>
            </div>
            {/* Resume */}

            {/* About Me and Job Positions */}
            <div className="m-4 row">
                <div className="col-12 col-lg-6 p-2 p-lg-4 my-3">
                    <h1 className="font-funny mb-3">
                        <span className="text-danger">About</span> Me
                    </h1>
                    <p style={{ textAlign: "justify" }}>
                        As a dedicated developer with a focus on web, desktop, and mobile platforms, I’m passionate about bringing creative ideas to life through well-crafted and effective solutions. I specialize in turning concepts into high-quality, functional applications and strive to deliver impactful digital experiences. Let’s collaborate and make your next project a standout success!
                    </p>
                </div>
                <div className="col-12 col-lg-6 p-2 p-lg-4 my-3">
                    <h1 className="font-funny mb-3">
                        <span className="text-primary">Job</span> Positions
                    </h1>
                    <div className="d-flex align-items-center flex-wrap justify-content-flex-start">
                        <p className="d-block px-3 py-1 me-2 border border-2 rounded-pill">Project Manager</p>
                        <p className="d-block px-3 py-1 me-2 border border-2 rounded-pill">Full Stack Developer</p>
                        <p className="d-block px-3 py-1 me-2 border border-2 rounded-pill">MERN Stack Developer</p>
                        <p className="d-block px-3 py-1 me-2 border border-2 rounded-pill">Backend Developer</p>
                        <p className="d-block px-3 py-1 me-2 border border-2 rounded-pill">Frontend Developer</p>
                        <p className="d-block px-3 py-1 me-2 border border-2 rounded-pill">React Native Developer</p>
                    </div>
                </div>
            </div>
            {/* About Me and Job Positions */}

            {/* Education */}
            <div className="m-5">
                <h1 className="font-funny mb-3">
                    <span className="text-success">Edu</span>cation
                </h1>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <a className="card my-3 text-decoration-none text-bg-dark" href="https://utdallas.edu/" target="_blank" rel="noreferrer">
                            <div className="row">
                                <div className="col-md-3 d-flex align-items-center justify-content-center">
                                    <img src="/utd-logo.png" className="card-img-top bg-light" alt="Univerity of Texas at Dallas" />
                                </div>
                                <div className="col-md-9">
                                    <div className="card-body">
                                        <h5 className="card-title">The University of Texas at Dallas</h5>
                                        <p className="card-text mb-0">Texas, USA</p>
                                        <p className="card-text mb-0">Master's in Computer Science</p>
                                        <p className="card-text">Spring 2024 - Fall 2025</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-12 col-lg-6">
                        <a className="card my-3 text-decoration-none text-bg-dark" href="https://gtu.ac.in" target="_blank" rel="noreferrer">
                            <div className="row">
                                <div className="col-md-3 d-flex align-items-center">
                                    <img src="/gtu-logo.png" className="card-img-top border-0 rounded" alt="Gujarat Technological Univerity" />
                                </div>
                                <div className="col-md-9">
                                    <div className="card-body">
                                        <h5 className="card-title">Gujarat Technological University</h5>
                                        <p className="card-text mb-0">Gujarat, India</p>
                                        <p className="card-text mb-0">Bachelor's in Information Technology</p>
                                        <p className="card-text">Fall 2019 - Spring 2023</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            {/* Education */}

            {/* Experience */}
            <div className="m-5">
                <h1 className="font-funny mb-3">
                    <span className="text-info">Exp</span>erience
                </h1>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <a className="card my-3 text-decoration-none text-bg-dark" href="https://utdallas.edu" target="_blank" rel="noreferrer">
                            <img src="/utd-logo.png" className="card-img-top border-0 rounded bg-light" alt="Gujarat Technological Univerity" />

                            <div className="card-body">
                                <h5 className="card-title">UTD Testing Center</h5>
                                <p className="card-text mb-0 small">Dallas, TX USA</p>
                                <p className="card-text mb-0 small">Exam Proctor</p>
                                <p className="card-text small">June 2024 - Present</p>
                            </div>
                        </a>
                    </div>
                    <div className="col-12 col-md-4">
                        <a className="card w-100 my-3 text-decoration-none text-bg-dark" href="https://k12.utdallas.edu" target="_blank" rel="noreferrer">
                            <img src="/utd-logo.png" className="card-img-top border-0 rounded bg-light" alt="Gujarat Technological Univerity" />

                            <div className="card-body">
                                <h5 className="card-title">UTD ECS Dept.</h5>
                                <p className="card-text mb-0 small">Dallas, TX USA</p>
                                <p className="card-text mb-0 small">CS Outreach Instructor</p>
                                <p className="card-text small">April 2024 - June 2024</p>
                            </div>
                        </a>
                    </div>
                    <div className="col-12 col-md-4">
                        <a className="card my-3 text-decoration-none text-bg-dark" href="https://3rddigital.com" target="_blank" rel="noreferrer">
                            <img src="/3rd-logo.png" className="card-img-top h-100 border-0 rounded" alt="Gujarat Technological Univerity" />

                            <div className="card-body">
                                <h5 className="card-title">3rd Digital Pvt. Ltd.</h5>
                                <p className="card-text mb-0 small">Ahmedabad, GJ INDIA</p>
                                <p className="card-text mb-0 small">Jr. MERN Developer</p>
                                <p className="card-text small">March 2023 - October 2023</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            {/* Experience */}

            {/* Projects */}
            <div className="m-5">
                <h1 className="font-funny mb-3">
                    <span className="text-warning">Pro</span>jects
                </h1>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 d-flex mb-3">
                        <div className="card text-bg-dark">
                            <div className="card-body d-flex justify-content-between flex-column">
                                <div className="mb-3">
                                    <h3 className="card-title font-funny">LeoSecurity</h3>
                                    <p className="card-text" style={{ textAlign: "justify" }}>
                                        This desktop application protects your passwords, notes, and remote server settings with AES-CBC-256 bit encryption, ensuring your sensitive data remains secure and accessible only to you.
                                    </p>
                                </div>
                                <a href="https://security.leomalay.com" target="_blank" rel="noreferrer" className="btn btn-warning">
                                    <i class="bi bi-link-45deg"></i> Visit
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 d-flex mb-3">
                        <div className="card text-bg-dark">
                            <div className="card-body d-flex justify-content-between flex-column">
                                <div className="mb-3">
                                    <h3 className="card-title font-funny">StudyLeo</h3>
                                    <p className="card-text" style={{ textAlign: "justify" }}>
                                        An essential tool for students, this app helps you track exams, deadlines, and notes, all while keeping your to-do list organized. Stay on top of your academic game and never miss a beat.
                                    </p>
                                </div>
                                <a href="https://study.leomalay.com" target="_blank" rel="noreferrer" className="btn btn-warning">
                                    <i class="bi bi-link-45deg"></i> Visit
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Projects */}
            <Footer />
        </div>
    );
};

export default App;
